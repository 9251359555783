import { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '@monorepo/tools/src/lib/hooks/utils/use-on-click-outside';
import { PrimaryLink, PrimaryText, SecondaryText } from '@monorepo/base/src/components/buttons/buttons';
import { Divider } from '@monorepo/base/src/components/divider/divider';
import { Checkbox } from '@monorepo/base/src/components/form/checkbox/checkbox';
import { List, ListItem } from '@monorepo/base/src/components/list/list';
import { Menu } from '@monorepo/base/src/components/menu/menu';
import { IndexLinerButton } from '../action-liner/liner-buttons';
import styles from './columns-picker.module.scss';
import { Column, VisibilityState } from '@tanstack/react-table';
import { getTheme } from '@monorepo/tools/src/lib/get-config';
import { IconFonts } from '@monorepo/base/src/components/icon/icon';
import { DataAttribute, suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { usePageSettings } from '@monorepo/tools/src/lib/hooks/tools/use-page-settings';
import { snakeCase } from 'change-case';

const theme = getTheme();

interface IColumnsPicker<T> {
	allColumns: Column<T>[];
	debugProps?: IDebugProps;
}

export function ColumnsPicker<T>(props: IColumnsPicker<T>) {
	const { allColumns, debugProps } = props;
	const [isColumnPickerOpen, setColumnPickerOpen] = useState<boolean>(false);
	const columnsPickerRef = useRef<HTMLDivElement>(null);
	const { dataAttrs } = debugProps || {};
	const { tableStore } = usePageSettings();

	const currentColumnsVisibility = tableStore?.getColumnVisibility() || {};

	const [columnsVisibility, setColumnsVisibility] = useState<VisibilityState>({});
	useEffect(() => {
		setColumnsVisibility(currentColumnsVisibility || {});
	}, [currentColumnsVisibility]);

	const onColumnPicker = () => {
		setColumnPickerOpen(!isColumnPickerOpen);
	};

	useOnClickOutside(columnsPickerRef, () => {
		setColumnPickerOpen(false);
	});

	const onPickColumn = (column: Column<T>) => {
		(!columnsVisibility[column.id] || Object.values(columnsVisibility).filter(Boolean).length > 4) &&
			setColumnsVisibility({
				...columnsVisibility,
				[column.id]: !columnsVisibility[column.id],
			});
	};

	return (
		<div className={styles.wrapper} ref={columnsPickerRef}>
			<IndexLinerButton>
				<SecondaryText
					debugProps={{ dataAttrs: suffixToDataAttr('_columns', dataAttrs) }}
					icon={'columns-03'}
					font={IconFonts.Outlined}
					iconColor={theme.actionLinerActionIconsColor}
					iconSize={'18px'}
					onClick={onColumnPicker}>
					Columns
				</SecondaryText>
			</IndexLinerButton>
			{isColumnPickerOpen ? (
				<Menu
					unstyled={true}
					isOpen={isColumnPickerOpen}
					alignToRight={true}
					debugProps={{ dataAttrs: [new DataAttribute('id', 'columns_menu')] }}>
					<div className={styles.columnPickerMenu}>
						<div className={styles.headWrapper}>
							<div className={styles.subtitle}>Column settings:</div>
							<PrimaryText
								width={'80px'}
								onClick={() =>
									setColumnsVisibility(allColumns.reduce((acc, column) => ({ ...acc, [column.id]: true }), {}))
								}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'columns_menu_select_all_button')] }}>
								Select all
							</PrimaryText>
						</div>
						<List className={styles.list}>
							{allColumns
								.filter(column => column.getCanHide())
								.map(column => {
									const title = column.columnDef.header?.toString();
									return (
										<ListItem
											debugProps={
												title
													? {
															dataAttrs: suffixToDataAttr(`_column_filter_${snakeCase(title)}`, dataAttrs),
													  }
													: undefined
											}
											className={styles.item}
											key={column.id}
											onClick={() => onPickColumn(column)}>
											<div className={styles.content}>
												<Checkbox
													className={styles.checkbox}
													onChange={e => e.stopPropagation()} //does nothing, just to prevent react warning
													checked={columnsVisibility[column.id]}
													debugProps={{ dataAttrs: [new DataAttribute('id', 'checkbox')] }}
												/>
												<span className={styles.title}>{title}</span>
											</div>
										</ListItem>
									);
								})}
						</List>
						<Divider />
						<div className={styles.actions}>
							<SecondaryText
								width={'60px'}
								onClick={() => setColumnsVisibility(tableStore?.getDefaultColumnVisibility() || {})}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'columns_menu_clear_button')] }}>
								Reset
							</SecondaryText>
							<PrimaryLink
								width={'80px'}
								disabled={allColumns.every(column => column.getIsVisible() === columnsVisibility[column.id])}
								onClick={() => {
									setColumnPickerOpen(false);
									tableStore?.setColumnVisibility(columnsVisibility);
								}}
								debugProps={{ dataAttrs: [new DataAttribute('id', 'columns_menu_apply_button')] }}>
								Apply
							</PrimaryLink>
						</div>
					</div>
				</Menu>
			) : null}
		</div>
	);
}
