import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import styles from './../../../../styles/pages.module.scss';
import { Helmet } from 'react-helmet-async';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { EntityActionsMenu } from '../../../../modules/components/entity-actions-menu/entity-actions-menu';
import { IMFPStore } from '../../../../modules/stores';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useParams } from 'react-router-dom';
import {
	Entities,
	IPerformanceOptionsFilterType,
	PerformancesColumns,
	useReportsList,
} from '../../../../modules/hooks/apis/use-reports-list';
import { useMetricsGet } from '../../../../modules/hooks/tools/use-metrics-get';
import { useCampaignActions } from '../../../../modules/hooks/actions/campaign.actions';
import { useSourcesHeaders } from '../../../../modules/hooks/theaders/sources.headers';
import { useSourcesActions } from '../../../../modules/hooks/actions/sources.actions';
import { ManifestoChart } from '../../../../modules/components/chart/manifesto-chart';
import { availablePerformanceToMetrics } from '../../../../modules/models/performance.model';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useMetrics } from '../../../../modules/hooks/tools/use-metrics';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { observer } from 'mobx-react';
import { PaginationState } from '@tanstack/react-table';
import { isFetchFromBackend } from '../../../../modules/helpers/reports.helpers';
import { isAbortError } from '@monorepo/tools/src/lib/tools/ask/guards';

export const CampaignSources = observer(() => {
	const { sourcesStore, campaignStore } = useStores<IMFPStore>();
	const { campaignId } = useParams();

	const reportsStore = sourcesStore.getReportsStore();
	const reportData = reportsStore.getData();
	const metricsStore = sourcesStore.getMetricsStore();

	if (!campaignId) {
		return null;
	}

	const { reportsError, fetchReports } = useReportsList(
		reportsStore,
		{
			entity: Entities.Sources,
			groupBys: [PerformancesColumns.Source],
			filters: [
				{
					column: PerformancesColumns.CampaignId,
					filterType: IPerformanceOptionsFilterType.Equals,
					value: [campaignId],
				},
			],
		},
		undefined
	);

	const { metricsError, fetchMetrics } = useMetricsGet(metricsStore, {
		entity: Entities.Chart,
		groupBys: [PerformancesColumns.Date, PerformancesColumns.Source],
		filters: [
			{
				column: PerformancesColumns.CampaignId,
				filterType: IPerformanceOptionsFilterType.Include,
				value: [campaignId],
			},
		],
	});
	const { currentRouteWithoutSpecialChars } = useRoute();

	const { columns } = useSourcesHeaders({ summary: reportData?.getSummary() });
	const { FiltersActions } = useSourcesActions();

	const { metrics, xLabels } = useMetrics(metricsStore.getData());

	const { EntityActions } = useCampaignActions();
	const campaign = campaignStore.getCrud().getData();

	return (
		<Page unstyled={true} className={styles.article}>
			<Helmet>
				<title>{campaign.getName() ? `${campaign.getName()} - ` : ''}Sources</title>
			</Helmet>
			<Pagebar classes={{ pagebar: styles.padding }}>
				<PagebarTitle>Sources</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
					<EntityActionsMenu title={'Campaign Actions'}>
						<EntityActions entity={campaign} />
					</EntityActionsMenu>
				</PagebarActions>
			</Pagebar>
			<ManifestoChart
				isLoading={metricsStore.getIsLoading()}
				metrics={metrics}
				labels={xLabels}
				legendOptions={Object.keys(availablePerformanceToMetrics)}
				isError={!!metricsError && !isAbortError(metricsError)}
			/>
			<SpacerY y={2} />
			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				totalRows={reportData?.getTotalRows() ? reportData?.getTotalRows()?.toLocaleString() : undefined}
				totalFilteredRows={reportData?.getTotalFilteredRows() ? reportData?.getTotalFilteredRows()?.toLocaleString() : undefined}
				columns={columns}
				classes={{ wrapper: styles.padding }}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'sources_campaign_table')] }}
				data={reportData?.getData() || []}
				isLoading={reportsStore.getIsLoading()}
				isWithPerformance={true}
				isLoadingPerformance={reportsStore.getIsLoading()}
				defaultSortBy={[{ id: 'revenue', desc: true }]}
				fetchReports={fetchReports}
				fetchMetrics={fetchMetrics}
				onPageChange={(state: PaginationState) => {
					if (isFetchFromBackend(reportData, state) && !reportsStore.getIsLoading()) {
						fetchReports({ appendData: true, resetOffset: false });
					}
				}}
				isError={!!reportsError && !isAbortError(reportsError)}>
				{() => {
					return {
						FiltersActions,
					};
				}}
			</ControlledTable>
		</Page>
	);
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const generateMockSources = () => {
	return {
		payload: {
			report: {
				data: [],
				totalRows: 10,
				summary: {
					impressions: 65441493,
					clicks: 1976349,
					conversions: 60718,
					cost: 371620.38,
					revenue: 194861.1,
					roas: 0.52,
					cpc: 0.19,
					cpa: 6.12,
					cpm: 0.01,
					cvr: 0,
					epc: 0.1,
					win_rate: 0,
					conv_val: 3.21,
					account_id: null,
				},
				segmentedSummary: {},
			},
		},
		message: 'Report received',
	};
};
