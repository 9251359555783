import React, { cloneElement, useEffect } from 'react';
import { IControlledStore, useStores } from '@monorepo/controlled/src/hooks/use-stores';
import styles from './announcement.module.scss';

export interface IAnnouncementInjectProps {
	id: number;
	closeAnnouncement?: () => void;
}

export interface IAnnouncement {
	children: JSX.Element;
	id: number;
}

// TODO - RONT css warning error and info fix
export const Announcement = (props: IAnnouncement) => {
	const { children, id = 0 } = props;
	const { announcementsStore } = useStores<IControlledStore>();

	const closeAnnouncement = () => {
		announcementsStore.removeAnnouncement(id);
	};

	useEffect(() => {
		const timer = setTimeout(() => {
			closeAnnouncement();
		}, 5000);
		return () => {
			clearTimeout(timer);
		};
	}, [id]);

	const smartChildren = React.Children.map(children, child => {
		return cloneElement(child, {
			closeAnnouncement,
			id,
		});
	});

	return (
		<div className={styles.announcementWrapper} onClick={e => e.stopPropagation()}>
			{smartChildren}
		</div>
	);
};
