import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect } from 'react';
import { ChannelModel, IChannel } from '../../models/channel.model';
import { IMFPStore } from '../../stores';
import { IEntityHeaders } from './index.headers';
import { IMergedHeadersReturn } from './index.headers';
import { useWithPerformanceHeaders } from './with-performance.headers';

const columnHelper = createColumnHelper<IChannel>();

export const useChannelHeaders = (props: IEntityHeaders = {}): IMergedHeadersReturn<ChannelModel> => {
	const { summary: summaryItem } = props;
	const { currentRouteWithoutSpecialChars } = useRoute();
	const { settingsStore } = useStores<IMFPStore>();
	const mergedPerforamnceHeaders = useWithPerformanceHeaders({ summaryItem }).columns;
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;
	// const { columns: specificColumns, columnVisibility: specificColumnVisibility } = useSpecificHeaders({
	// 	specificColumns: ['account_name', 'account_id', 'advertiser_id', 'advertiser_name'],
	// });

	useEffect(() => {
		if (tableStore) {
			if (Object.keys(tableStore.getColumnVisibility()).length === 0) {
				tableStore.setDefaultColumnVisibility({
					id: false,
					name: true,
					clicks: true,
					conversions: true,
					cost: true,
					revenue: true,
					roas: true,
					cpc: true,
					cpa: true,
					impressions: false,
					cpm: false,
					cvr: false,
					conv_val: false,
					// ...specificColumnVisibility,
					win_rate: false,
					epc: false,
				});
				tableStore?.setColumnVisibility(tableStore.getDefaultColumnVisibility());
			}
			if (tableStore.getSortingBy().length === 0) {
				tableStore.setSortingBy([
					{
						id: 'revenue',
						desc: true,
					},
				]);
			}
		}
	}, [tableStore]);

	return {
		columns: [
			columnHelper.accessor('SPACER_COLUMN', { header: '', enableHiding: false }),
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			columnHelper.accessor('name', {
				header: 'Name',
				enableHiding: false,
			}),
			...mergedPerforamnceHeaders,
			// ...specificColumns,
		],
	};
};
