// import { Icon } from '@monorepo/base/src/components/icon/icon';
import { randomNumber } from '@monorepo/tools/src/lib/utils/number';
import styles from './avatar.module.scss';
import { url } from '@monorepo/tools/src/lib/types/url';
// import { getTheme } from '@monorepo/tools/src/lib/get-config';

// const theme = getTheme();

interface IAvatar {
	url?: url;
	alt?: string;
	firstName?: string;
}

const colorsArray = ['#ec407a', '#512da8', '#004d40', '#01579b', '#33691e'];

export const Avatar = (props: IAvatar) => {
	const { url, alt, firstName } = props;
	if (!url) {
		return (
			// <Icon isMFP={true} color={theme.subtitleColor} size={'28px'}>
			// 	user-circle
			// </Icon>
			<div className={styles.noPictureAvatar} style={{ backgroundColor: colorsArray[parseInt(randomNumber(0, 4))] }}>
				{firstName?.charAt(0)}
			</div>
		);
	}
	return <img alt={alt} className={styles.avatar} src={url} />;
};
