import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { createColumnHelper } from '@tanstack/react-table';
import { useEffect } from 'react';
import { IMFPStore } from '../../stores';
import { IEntityHeaders } from './index.headers';
import { IMergedHeadersReturn } from './index.headers';
import { useWithPerformanceHeaders } from './with-performance.headers';
import { ISource, SourceModel } from '../../models/sources.model';
import { useSpecificHeaders } from './with-specific.headers';

const columnHelper = createColumnHelper<ISource>();

export const useSourcesHeaders = (props: IEntityHeaders = {}): IMergedHeadersReturn<SourceModel> => {
	const { summary: summaryItem } = props;
	const { currentRouteWithoutSpecialChars } = useRoute();
	const { settingsStore } = useStores<IMFPStore>();
	const mergedPerformanceHeaders = useWithPerformanceHeaders({ summaryItem }).columns;
	const { columns: specificColumns, columnVisibility: specificColumnVisibility } = useSpecificHeaders({
		specificColumns: ['accountName', 'accountId', 'advertiserId', 'advertiserName', 'campaignName', 'campaignId'],
	});
	const tableStore = settingsStore.pages.get(currentRouteWithoutSpecialChars)?.tableStore;

	useEffect(() => {
		if (tableStore) {
			if (Object.keys(tableStore.getColumnVisibility()).length === 0) {
				tableStore?.setDefaultColumnVisibility({
					id: false,
					name: true,
					channel_name: true,
					publisher_name: true,
					clicks: true,
					conversions: true,
					cost: true,
					revenue: true,
					roas: true,
					cpc: true,
					cpa: true,
					impressions: false,
					cpm: false,
					cvr: false,
					conv_val: false,
					...specificColumnVisibility,
					win_rate: false,
					epc: false,
				});
				tableStore?.setColumnVisibility(tableStore.getDefaultColumnVisibility());
			}
			if (tableStore.getSortingBy().length === 0) {
				tableStore.setSortingBy([
					{
						id: 'revenue',
						desc: true,
					},
				]);
			}
		}
	}, [tableStore]);

	return {
		columns: [
			columnHelper.accessor('SPACER_COLUMN', { header: '', enableHiding: false }),
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			columnHelper.accessor('name', {
				header: 'Name',
				enableHiding: false,
			}),
			columnHelper.accessor('channel_name', {
				header: 'Channel',
			}),
			columnHelper.accessor('publisher_name', {
				header: 'Publisher',
			}),
			...mergedPerformanceHeaders,
			...specificColumns,
		],
	};
};
