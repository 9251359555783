import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { ControlledDater } from '@monorepo/controlled/src/components/controlled-dater/controlled-dater';
import { Page, Pagebar, PagebarActions, PagebarTitle } from '@monorepo/base/src/components/page/page';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPStore } from '../../../../modules/stores';
import {
	Entities,
	IPerformanceOptionsFilterType,
	PerformancesColumns,
	useReportsList,
} from '../../../../modules/hooks/apis/use-reports-list';
import styles from './../../../../styles/pages.module.scss';
import { SpacerY } from '@monorepo/base/src/components/spacer/spacer';
import { ControlledTable } from '@monorepo/controlled/src/components/table-wrapper/table-wrapper';
import { useMetricsGet } from '../../../../modules/hooks/tools/use-metrics-get';
import { useMetrics } from '../../../../modules/hooks/tools/use-metrics';
import { useChannelHeaders } from '../../../../modules/hooks/theaders/channel.headers';
import { availablePerformanceToMetrics } from '../../../../modules/models/performance.model';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { useCampaignActions } from '../../../../modules/hooks/actions/campaign.actions';
import { EntityActionsMenu } from '../../../../modules/components/entity-actions-menu/entity-actions-menu';
import { useChannelActions } from '../../../../modules/hooks/actions/channel.actions';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { ManifestoChart } from '../../../../modules/components/chart/manifesto-chart';
import { Helmet } from 'react-helmet-async';
import { PaginationState } from '@tanstack/react-table';
import { isFetchFromBackend } from '../../../../modules/helpers/reports.helpers';
import { isAbortError } from '@monorepo/tools/src/lib/tools/ask/guards';

export const CampaignChannels = observer(() => {
	const { channelStore, campaignStore } = useStores<IMFPStore>();
	const { campaignId } = useParams();
	const { currentRouteWithoutSpecialChars } = useRoute();

	const reportsStore = channelStore.getReportsStore();
	const reportData = reportsStore.getData();
	const metricsStore = channelStore.getMetricsStore();
	const currentSegments = channelStore.getSegments();

	if (!campaignId) {
		return null;
	}

	const { reportsError, fetchReports } = useReportsList(
		reportsStore,
		{
			entity: Entities.Channels,
			groupBys: [PerformancesColumns.Channel],
			filters: [
				{
					column: PerformancesColumns.CampaignId,
					filterType: IPerformanceOptionsFilterType.Equals,
					value: [campaignId],
				},
			],
		},
		undefined,
		currentSegments
	);

	const { metricsError, fetchMetrics } = useMetricsGet(metricsStore, {
		entity: Entities.Chart,
		groupBys: [PerformancesColumns.Date],
		filters: [
			{
				column: PerformancesColumns.CampaignId,
				filterType: IPerformanceOptionsFilterType.Include,
				value: [campaignId],
			},
		],
	});

	const { columns } = useChannelHeaders({ summary: reportData?.getSummary() });
	const { FiltersActions, SegmentActions } = useChannelActions({ currentSegments });
	const { metrics, xLabels } = useMetrics(metricsStore.getData());

	const { EntityActions } = useCampaignActions();
	const campaign = campaignStore.getCrud().getData(); // This campaign will be stored from breadcrumbs

	return (
		<Page unstyled={true} className={styles.article}>
			<Helmet>
				<title>{campaignStore.getCrud().getData().getName() || 'Campaign'} - Channels</title>
			</Helmet>
			<Pagebar classes={{ pagebar: styles.padding }}>
				<PagebarTitle>Channels</PagebarTitle>
				<PagebarActions>
					<ControlledDater />
					<EntityActionsMenu title={'Campaign Actions'}>
						<EntityActions entity={campaign} />
					</EntityActionsMenu>
				</PagebarActions>
			</Pagebar>
			<ManifestoChart
				isLoading={metricsStore.getIsLoading()}
				metrics={metrics}
				labels={xLabels}
				legendOptions={Object.keys(availablePerformanceToMetrics)}
				isError={!!metricsError && !isAbortError(metricsError)}
			/>
			<SpacerY y={2} />
			<ControlledTable
				currentRoute={currentRouteWithoutSpecialChars}
				totalRows={reportData?.getTotalRows() ? reportData?.getTotalRows()?.toLocaleString() : undefined}
				totalFilteredRows={reportData?.getTotalFilteredRows() ? reportData?.getTotalFilteredRows()?.toLocaleString() : undefined}
				columns={columns}
				classes={{ wrapper: styles.padding }}
				debugProps={{ dataAttrs: [new DataAttribute('id', 'channels_campaign_table')] }}
				data={reportData?.getData() || []}
				isLoading={reportsStore.getIsLoading()}
				isWithPerformance={true}
				isLoadingPerformance={reportsStore.getIsLoading()}
				defaultSortBy={[{ id: 'revenue', desc: true }]}
				fetchReports={fetchReports}
				fetchMetrics={fetchMetrics}
				onPageChange={(state: PaginationState) => {
					if (isFetchFromBackend(reportData, state) && !reportsStore.getIsLoading()) {
						fetchReports({ appendData: true, resetOffset: false });
					}
				}}
				isError={!!reportsError && !isAbortError(reportsError)}>
				{() => {
					return {
						FiltersActions,
						SegmentActions,
					};
				}}
			</ControlledTable>
		</Page>
	);
});
