export const currencyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', maximumFractionDigits: 0 });
export const currencyFormatterWithDecimals = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	maximumFractionDigits: 2,
});
export const numberFormatter = new Intl.NumberFormat('en', { notation: 'compact', maximumSignificantDigits: 10 });

export const numberFormatterWithCommas = new Intl.NumberFormat('en');

export const numberFormatterWithDecimals = new Intl.NumberFormat('en', { maximumFractionDigits: 2 });

export const numberFormatterWithDecimalsCompacted = new Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 2 });

export const currencyFormat = (value?: number, currencyWithDecimal?: boolean): string | null => {
	if (value === undefined || value === null) {
		return null;
	}
	return currencyWithDecimal ? currencyFormatterWithDecimals.format(value) : currencyFormatter.format(value);
};

export const percents = (value?: number): string | null => {
	if (!value) {
		return '0%';
	}
	return `${parseInt((value * 100).toLocaleString()) || 0}%`;
};

export const number = (value?: number, decimal?: boolean, compact = true, commas = false): string | null => {
	if (decimal) {
		if (compact) {
			return value !== null && value !== undefined ? numberFormatterWithDecimalsCompacted.format(value) : null;
		} else {
			return value !== null && value !== undefined ? numberFormatterWithDecimals.format(value) : null;
		}
	}

	if (commas) {
		return value !== null && value !== undefined ? numberFormatterWithCommas.format(value) : null;
	}

	//TODO remove this .replace later and make it work only with Intl.NumberFormat
	return value !== null && value !== undefined ? numberFormatter.format(value).replace(/\.(\d)+/, '') : null;
};

export const randomNumber = (min: number, max: number, places = 2): string => {
	const value = Math.random() * (max - min) + min;
	return Number.parseFloat(`${value}`).toFixed(places);
};

export const isStringInfinityOrNaN = (val: string | number) => {
	if (val === 'NaN' || val === 'Infinity') {
		return true;
	}

	return false;
};

export enum NumberActions {
	Equals = 'equals',
	LessThan = 'less than',
	LessThanEquals = 'less than equals',
	GreaterThan = 'greater than',
	GreaterThanEquals = 'greater than equals',
}
export type numberAction =
	| NumberActions.Equals
	| NumberActions.LessThan
	| NumberActions.LessThanEquals
	| NumberActions.GreaterThan
	| NumberActions.GreaterThanEquals;

export const numberFuncs = {
	[NumberActions.Equals]: (number: number, filterValue: number) => number === filterValue,
	[NumberActions.LessThan]: (number: number, filterValue: number) => number < filterValue,
	[NumberActions.LessThanEquals]: (number: number, filterValue: number) => number <= filterValue,
	[NumberActions.GreaterThan]: (number: number, filterValue: number) => number > filterValue,
	[NumberActions.GreaterThanEquals]: (number: number, filterValue: number) => number >= filterValue,
};
