import { createColumnHelper, sortingFns } from '@tanstack/react-table';
import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import { isSubRow } from '@monorepo/base/src/components/table/table';
import { CampaignModel, ICampaign } from '../../models/campaign.model';
import routes from '../../routes/index.routes';
import { useCampaignActions } from '../actions/campaign.actions';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPStore } from '../../stores';
import { IEntityHeaders, renderSubRow } from './index.headers';
import { IMergedHeadersReturn } from './index.headers';
import { useWithPerformanceHeaders } from './with-performance.headers';
import { Tag } from '@monorepo/base/src/components/tag/tag';
import { mapToIcon, StatusType, mapToTagStatus, StatusEnum } from '../../enums/status';
import { Icon, IconFonts } from '@monorepo/base/src/components/icon/icon';
import styles from './headers.module.scss';
import { sentenceCase } from 'change-case';
import { currencyFormat, isStringInfinityOrNaN } from '@monorepo/tools/src/lib/utils/number';
import dayjs from 'dayjs';
import { Tooltip } from '@monorepo/controlled/src/components/tooltip/tooltip/tooltip';
import { Directions } from '@monorepo/base/src/components/buttons/buttons';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { Fragment, useEffect } from 'react';
import { QuickActionsMenu } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { useSpecificHeaders } from './with-specific.headers';
import { Devices, devicesIconMapping } from '../../enums/devices';
import { DataAttribute } from '@monorepo/tools/src/lib/models/data-attr.model';
import { TableMergeColumn } from '@monorepo/base/src/components/table/table-merge-column/table-merge-column';
import { TableSelectionHeader, TableSelectionCell } from '@monorepo/base/src/components/table/table-selection-cell/table-selection-cell';
import { usePageSettings } from '@monorepo/tools/src/lib/hooks/tools/use-page-settings';

const columnHelper = createColumnHelper<ICampaign>();

export const useCampaignHeaders = (props: IEntityHeaders = {}): IMergedHeadersReturn<CampaignModel> => {
	const { summary: summaryItem, actionDebugProps } = props;
	const { currentRoute } = useRoute();
	const isNestedAdvertiser = currentRoute.includes('advertisers');
	const mergedPerforamnceHeaders = useWithPerformanceHeaders({ summaryItem }).columns;
	const { columns: specificColumns, columnVisibility: specificColumnVisibility } = useSpecificHeaders({
		specificColumns: ['accountId', 'accountName', 'advertiserId', 'advertiserName'],
	});
	const { sidebarStore } = useStores<IMFPStore>();
	const { EntityActions } = useCampaignActions({ debugProps: actionDebugProps });
	const tableStore = usePageSettings().tableStore;

	useEffect(() => {
		if (tableStore) {
			if (Object.keys(tableStore.getColumnVisibility()).length === 0) {
				tableStore.setDefaultColumnVisibility({
					id: true,
					advertiserName: !isNestedAdvertiser,
					name: true,
					status: true,
					geo: true,
					...specificColumnVisibility,
					totalBudget: true,
					dailyBudget: true,
					defaultBid: true,
					landingPage: true,
					startTime: true,
					endTime: true,
					devices: true,
					strategyType: true,
					isDeepLink: true,
					optimizationMode: true,
					languages: true,
					clicks: true,
					conversions: true,
					cost: true,
					revenue: true,
					roas: true,
					cpc: true,
					cpa: true,
					cpm: true,
					cvr: true,
					impressions: false,
					epc: false,
					win_rate: false,
					conv_val: false,
				});
				tableStore.setColumnVisibility(tableStore.getDefaultColumnVisibility());
			}

			if (tableStore.getSortingBy().length === 0) {
				tableStore.setSortingBy([
					{
						id: 'revenue',
						desc: true,
					},
				]);
			}
		}
	}, [tableStore]);

	return {
		columns: [
			columnHelper.display({
				id: 'selection',
				enableHiding: false,
				header: headerProps => <TableSelectionHeader headerProps={headerProps} />,
				cell: cellProps => {
					const { row } = cellProps;
					const campaign = new CampaignModel(row.original);

					if (isSubRow(row)) {
						return <span></span>;
					}
					if (!EntityActions) {
						return null;
					}
					return (
						<TableMergeColumn>
							<TableSelectionCell cellProps={cellProps} />
							<QuickActionsMenu>
								<EntityActions entity={campaign} />
							</QuickActionsMenu>
						</TableMergeColumn>
					);
				},
			}),
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			columnHelper.accessor('name', {
				header: 'Name',
				enableHiding: false,
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return renderSubRow({ row });
					}
					return (
						<TableLink
							to={routes.campaigns.channels(row.getValue('id'))}
							toEdit={routes.campaigns.edit(row.getValue('id'))}
							onClick={() => {
								sidebarStore.setSidebarOpen(false);
							}}>
							{row.getValue('name')}
						</TableLink>
					);
				},
			}),
			columnHelper.accessor('status', {
				header: 'Status',
				cell: info => {
					const decideActions = (campaign: CampaignModel) => {
						const _status = campaign.getStatus();
						if (_status === StatusEnum.ACTIVE) {
							return { pause: true };
						}
						if (_status === StatusEnum.PAUSED) {
							return { enable: true };
						}
						if (_status === StatusEnum.DRAFT) {
							return { enable: true, pause: true };
						}
					};

					const { row } = info;
					if (isSubRow(row)) {
						return <span></span>;
					}
					const campaign = new CampaignModel(row.original);
					const status = campaign.getStatus() as StatusType;
					const mappedStatus = mapToIcon(status);

					if (status === StatusEnum.DELETED || status === StatusEnum.DRAFT) {
						return (
							<Tag type={mapToTagStatus(status?.toUpperCase() as StatusType)}>
								<Icon
									className={styles[mappedStatus.className]}
									size={'13px'}
									color={mappedStatus.color}
									font={IconFonts.Outlined}
									isMFP={true}>
									{mappedStatus.icon}
								</Icon>
								{`${sentenceCase(row.getValue('status'))}`}
							</Tag>
						);
					}

					return (
						<Fragment>
							{EntityActions ? (
								<QuickActionsMenu
									toggler={
										<Tag
											type={mapToTagStatus(status)}
											debugProps={{ dataAttrs: [new DataAttribute('id', 'status_toggler')] }}>
											<Icon
												className={styles[mappedStatus.className]}
												size={'13px'}
												color={mappedStatus.color}
												font={IconFonts.Outlined}
												isMFP={true}>
												{mappedStatus.icon}
											</Icon>
											{`${sentenceCase(row.getValue('status'))}`}
										</Tag>
									}>
									<EntityActions entity={campaign} actions={decideActions(campaign)} />
								</QuickActionsMenu>
							) : null}
						</Fragment>
					);
				},
			}),
			...specificColumns,
			columnHelper.accessor('geo', {
				header: 'Country',
				cell: info => {
					const { row } = info;
					let country = row.getValue('geo') as string; //TODO revert this shitty hotfix

					if (country === 'United States') {
						country = 'US';
					}

					if (country === null) {
						country = 'N/A';
					}
					// TODO - image component
					return (
						<div className={styles.countries}>
							<img src={`/assets/country_logos/${country === 'N/A' ? 'unknown' : country}.svg`} alt={country} />
							<span>{country}</span>
						</div>
					);
				},
			}),
			columnHelper.accessor('totalBudget', {
				header: 'Total Budget',
				cell: ({ row }) => (
					<span>
						{!isStringInfinityOrNaN(row.getValue('totalBudget')) ? currencyFormat(row.getValue('totalBudget')) ?? '0' : '0'}
					</span>
				),
				sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			}),
			columnHelper.accessor('dailyBudget', {
				header: 'Daily Budget',
				cell: ({ row }) => (
					<span>
						{!isStringInfinityOrNaN(row.getValue('dailyBudget')) ? currencyFormat(row.getValue('dailyBudget')) ?? '0' : '0'}
					</span>
				),
				sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			}),
			columnHelper.accessor('defaultBid', {
				header: 'Default Bid',
				cell: ({ row }) => (
					<span>
						{!isStringInfinityOrNaN(row.getValue('defaultBid')) ? currencyFormat(row.getValue('defaultBid')) ?? '0' : '0'}
					</span>
				),
				sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			}),
			columnHelper.accessor('landingPage', {
				header: 'Landing Page',
				cell: ({ row }) => {
					const landingUrl: string = row.getValue('landingPage') ? row.getValue('landingPage') : '';
					return (
						<a className={styles.landingPageCell} target="_blank" href={landingUrl}>
							{landingUrl.length > 20 ? `${landingUrl.slice(20)}...` : landingUrl}
						</a>
					);
				},
				sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			}),
			columnHelper.accessor('isDeepLink', {
				header: 'Deep Link',
				cell: ({ row }) => {
					return <div className={styles.centeredCell}>{row.getValue('isDeepLink') ? 'V' : 'X'}</div>;
				},
				sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			}),
			columnHelper.accessor('startTime', {
				header: 'Start At',
				cell: ({ row }) => <span>{row.getValue('startTime') ? dayjs(row.getValue('startTime')).format('YYYY-MM-DD') : '-'}</span>,
				sortingFn: (rowA, rowB, columnId) => sortingFns.datetime(rowA, rowB, columnId),
			}),
			columnHelper.accessor('endTime', {
				header: 'End At',
				cell: ({ row }) => <span>{row.getValue('endTime') ? dayjs(row.getValue('endTime')).format('YYYY-MM-DD') : '-'}</span>,
				sortingFn: (rowA, rowB, columnId) => sortingFns.datetime(rowA, rowB, columnId),
			}),
			columnHelper.accessor('devices', {
				header: 'Devices',
				cell: ({ row }) => {
					const devicesToDisplay: string[] = Array.isArray(row.getValue('devices'))
						? row.getValue('devices')
						: Object.values(Devices);
					return (
						<div className={styles.centeredCell}>
							{devicesToDisplay
								.filter(device => devicesIconMapping[device])
								.map((device: string, i) => (
									<Tooltip key={i} direction={Directions.FromRight} content={device} bottom={'10px'}>
										<Icon color={'#6366f1'} size={'16px'} isMFP={true} font={IconFonts.Outlined}>
											{devicesIconMapping[device]}
										</Icon>
									</Tooltip>
								))}
						</div>
					);
				},
			}),
			columnHelper.accessor('strategyType', {
				header: 'Strategy',
				cell: ({ row }) => {
					const display: string = row.getValue('strategyType');
					return <span>{display}</span>;
				},
				sortingFn: (rowA, rowB, columnId) => sortingFns.alphanumeric(rowA, rowB, columnId),
			}),
			...mergedPerforamnceHeaders,
		],
	};
};
