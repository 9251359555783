import { Dispatch, Fragment, SetStateAction } from 'react';
import { BarLoader } from '@monorepo/base/src/components/bar-loader/bar-loader';
import { Skeleton } from '@monorepo/base/src/components/skeleton/skeleton';
import { Select } from '../../select/select';

interface IFilterOptions {
	options: string[];
	value?: string;
	onFilterOption: (arg0: string | string[]) => void;
	onEnumFilterOption?: Dispatch<SetStateAction<string>>;
	isLoading?: boolean;
	placeholder?: string;
	onCancel?: () => void;
	isSelectAll?: boolean;
	listClassName?: string;
	isMultiple?: boolean;
	isUppercasedEnum?: boolean;
}

export const FilterOptions = (props: IFilterOptions) => {
	const {
		value,
		options,
		isLoading = false,
		onFilterOption,
		onEnumFilterOption,
		placeholder,
		onCancel,
		isSelectAll = false,
		listClassName,
		isMultiple = true,
	} = props;

	//TODO fix this shit.. for statuses only..
	if (!onEnumFilterOption) {
		return (
			<Fragment>
				<BarLoader is={isLoading} />
				<Select
					placeholder={placeholder}
					values={options}
					onApply={_value => onFilterOption(_value)}
					disabled={isLoading}
					defaultValue={value}
					multiple={isMultiple}
					onCancel={onCancel}
					isSelectAll={isSelectAll}
					listClassName={listClassName}
				/>
				<Skeleton is={isLoading} rectanglesAmount={6} />
			</Fragment>
		);
	}

	return (
		<Fragment>
			<BarLoader is={isLoading} />
			<Select
				placeholder={placeholder}
				values={options}
				onClick={_value => {
					onEnumFilterOption(_value);
				}}
				disabled={isLoading}
				defaultValue={value}
				multiple={isMultiple}
				onCancel={onCancel}
				isSelectAll={isSelectAll}
				listClassName={listClassName}
			/>
			<Skeleton is={isLoading} rectanglesAmount={6} />
		</Fragment>
	);
};
