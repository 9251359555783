import { makeAutoObservable } from 'mobx';
import { StringActions, stringAction } from '@monorepo/tools/src/lib/utils/string';
import { NumberActions, numberAction } from '@monorepo/tools/src/lib/utils/number';

//TODO remove after implementing new filters to adminx
export enum StringFilterOptionsEnums {
	AccountName = 'accountName',
	AdvertiserName = 'advertiserName',
	CampaignName = 'campaignName',
	ChannelName = 'channelName',
	Status = 'status',
	BrandName = 'brandName',
	CreativeGroupDesign = 'creativegroupDesign',
	LandingPage = 'landingPage',
}

export enum ReportFilterActionEnum {
	CONTAINS = 'CONTAINS',
	IN = 'IN',
	NOT_IN = 'NOT_IN',
	EQUALS = 'EQUALS',
	NOT_EQUALS = 'NOT_EQUALS',
	GREATER = 'GREATER_THAN',
	GREATER_AND_EQUALS = 'GREATER_AND_EQUALS',
	LESS = 'LESS_THAN',
	LESS_AND_EQUALS = 'LESS_AND_EQUALS',
	IS_NULL = 'IS_NULL',
	IS_NOT_NULL = 'IS_NOT_NULL',
	NOT_CONTAINS = 'DOES_NOT_CONTAINS',
}

export const FilterActionLabelToActionEnum = {
	[NumberActions.Equals]: ReportFilterActionEnum.EQUALS,
	[NumberActions.LessThan]: ReportFilterActionEnum.LESS,
	[NumberActions.LessThanEquals]: ReportFilterActionEnum.LESS_AND_EQUALS,
	[NumberActions.GreaterThan]: ReportFilterActionEnum.GREATER,
	[NumberActions.GreaterThanEquals]: ReportFilterActionEnum.GREATER_AND_EQUALS,
	[StringActions.Contains]: ReportFilterActionEnum.CONTAINS,
	[StringActions.DoesNotEqual]: ReportFilterActionEnum.NOT_EQUALS,
	[StringActions.DoesNotContain]: ReportFilterActionEnum.NOT_CONTAINS,
};

export interface IFilterComponentProps {
	filter?: FilterModel;
	onCancel: () => void;
	onApply: () => void;
	addFilter: (arg0: FilterModel) => void;
	editFilter: (arg0: number, arg1: FilterModel) => void;
}

export type FilterComponent = (filterComponentProps: IFilterComponentProps) => JSX.Element;

export interface IFilter {
	label: string;
	value: string | string[] | number;
	action?: stringAction | numberAction;
	prototype: IFilterProto;
	index?: number;
	isUppercasedEnum?: boolean;
}

export interface IFilterProto {
	MenuComponent: string;
	FilterComponent: (props: IFilterComponentProps) => JSX.Element;
	prop: string;
	// eslint-disable-next-line
	filterFunc: (data: Array<any>, filter: FilterModel) => Array<any>;
	multi?: boolean;
	deletable?: boolean;
	isUppercasedEnum?: boolean;
}

export class FilterModel implements IFilter {
	label: string;
	value: string | string[] | number;
	action?: stringAction | numberAction;
	prototype: IFilterProto;
	index?: number;

	constructor(filter: IFilter) {
		this.label = filter.label;
		this.value = filter.value;
		this.action = filter.action;
		this.index = filter.index;
		this.prototype = filter.prototype;
		makeAutoObservable(this);
	}

	setPrototype(proto: IFilterProto) {
		this.prototype = proto;
	}
}
