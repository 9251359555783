import { observer } from 'mobx-react';
import { flexRender, ColumnDef, Row, ColumnSort, RowData, SortingState } from '@tanstack/react-table';
import { route } from '@monorepo/tools/src/lib/types/url';
import { InjectedTableActionsProps, IRowProps, ITableLiners, ITableProps, Table } from '@monorepo/base/src/components/table/table';
import { PrimaryLink } from '@monorepo/base/src/components/buttons/buttons';
import { SkeletonCell } from '@monorepo/base/src/components/table/table-skeleton/table-skeleton';
import { FilterModel } from '../../models/filter.model';
import { memo, useEffect } from 'react';
import { IDebugProps } from '@monorepo/tools/src/lib/interfaces/debug';
import { DataAttribute, generateDataAttrs, suffixToDataAttr } from '@monorepo/tools/src/lib/models/data-attr.model';
import { usePageSettings } from '@monorepo/tools/src/lib/hooks/tools/use-page-settings';
import { snakeCase } from 'change-case';
import { PaginationState, VisibilityState } from '@tanstack/react-table';
import { IRequestFlags } from '../../stores/http.store';
import { IControlledStore, useStores } from '../../hooks/use-stores';

declare module '@tanstack/table-core' {
	// eslint-disable-next-line
	interface ColumnMeta<TData extends RowData, TValue> {
		isPerformance?: boolean;
		colSpan?: number;
		// TODO add total line here
	}
}

export interface IServerPagination {
	offset: number;
	limit: number;
}

interface IControlledTableProps<T> extends ITableProps<T> {
	// TODO - fix this any
	// https://github.com/TanStack/table/issues/4382
	columns: ColumnDef<T, unknown>[];
	data: Array<T>;
	children?(props: InjectedTableActionsProps<T>): ITableLiners;
	createRoute?: route;
	isLoading?: boolean;
	isLoadingPerformance?: boolean;
	isWithPerformance?: boolean; // TODO - remove this and use isLoadingPerformance ??
	defaultSortBy?: ColumnSort[];
	debugProps?: IDebugProps;
	isError?: boolean;
	isActionLiner?: boolean;
	currentRoute: string;
	onPageChange?: (pagination: PaginationState) => void;
	fetchReports?: (flags?: IRequestFlags) => void;
	fetchMetrics?: (flags?: IRequestFlags) => void;
	isEmptyAndDisabled?: boolean;
	columnVisibility?: VisibilityState;
}

function theFilter<T>(data: Array<T>, filters?: Map<number, FilterModel>, forceFilterBackend?: boolean) {
	if (!filters || forceFilterBackend) {
		return data;
	}

	let filteredData = data;
	Array.from(filters).forEach(([, filter]) => {
		const { prototype } = filter;
		const { filterFunc } = prototype;
		if (!filterFunc) {
			return filteredData;
		}
		filteredData = filterFunc(filteredData, filter);
	});

	return filteredData;
}

export const _ControlledTable = observer(<T extends IRowProps<T>>(props: IControlledTableProps<T>) => {
	const {
		columns,
		data,
		children,
		isLoading,
		isLoadingPerformance,
		createRoute,
		isWithPerformance,
		debugProps,
		currentRoute,
		onPageChange,
		totalRows,
		totalFilteredRows,
		fetchReports,
		fetchMetrics,
		isActionLiner = true,
		isEmptyAndDisabled,
		columnVisibility,
		isGlobalFilter,
		...rest
	} = props;

	const { tooltipsStore } = useStores<IControlledStore>();
	let { defaultSortBy } = props;
	const { dataAttrs } = debugProps || {};
	const { tableStore } = usePageSettings(currentRoute);

	// const [pagination, setPagination] = useState<{ offset: number; limit?: number }>({ offset: 0, limit: undefined });

	let TableActions: ITableLiners = {};
	let performanceProps = {};
	const currentFilters = tableStore?.getFiltersStore(currentRoute)?.getCurrentFilters();
	const filterTerm = tableStore?.getFilterTerm();
	const statusFilterLength = currentFilters?.entries()?.next()?.value?.[1]?.value?.length;

	const totalRowsInt = parseInt(`${totalRows}`.replace(',', ''));
	const filteredData = totalRowsInt > data.length ? data : theFilter(data, currentFilters, Boolean(fetchReports || fetchMetrics));

	useEffect(() => {
		tooltipsStore.removeTooltips();
		if (fetchReports) {
			// && (totalRowsInt > data.length || !totalRowsInt)) {
			tableStore?.setPageIndex(0);
			fetchReports({ appendData: false, resetOffset: true });
		}
		if (fetchMetrics) {
			fetchMetrics();
		}
	}, [JSON.stringify(Array.from((currentFilters || new Map()).values())), filterTerm, statusFilterLength, JSON.stringify(defaultSortBy)]);

	const renderWithPerformance = (row: Row<T>, { className }: { className: string }): JSX.Element[] => {
		return row.getVisibleCells().map(cell => {
			const columnHeader = cell.column.columnDef.header;
			const columnName = typeof columnHeader === 'string' ? [new DataAttribute('id', `${snakeCase(columnHeader)}_cell`)] : undefined;

			return (
				<td key={cell.id} className={className} colSpan={cell.column.columnDef.meta?.colSpan} {...generateDataAttrs(columnName)}>
					{isLoadingPerformance && cell.column.columnDef.meta?.isPerformance ? (
						<SkeletonCell />
					) : (
						flexRender(cell.column.columnDef.cell, cell.getContext())
					)}
				</td>
			);
		});
	};

	if (isWithPerformance) {
		performanceProps = {
			renderCell: renderWithPerformance,
			isBarLoader: isLoading || isLoadingPerformance,
			defaultSortBy: defaultSortBy || [{ id: 'wins', desc: true }],
		};
	}

	if (!defaultSortBy) {
		defaultSortBy = [{ id: 'id', desc: false }];
	}

	if (!tableStore) {
		return null;
	}

	return (
		<Table<T>
			totalRows={totalFilteredRows || totalRows}
			data={filteredData}
			columns={columns}
			isLoading={isLoading}
			isBarLoader={isLoading}
			columnVisibility={columnVisibility || tableStore.getColumnVisibility()}
			defaultSortBy={defaultSortBy}
			debugProps={debugProps}
			isSummary={isWithPerformance}
			globalFilter={tableStore.getFilterTerm()}
			setGlobalFilter={(val: string) => tableStore.setFilterTerm(val)}
			onPageChange={(paginatonProps: PaginationState) => {
				tooltipsStore.removeTooltips();
				const { pageIndex } = paginatonProps;
				tableStore.setPageIndex(pageIndex);
				if (onPageChange) {
					onPageChange(paginatonProps);
				}
			}}
			onPageSize={({ pageSize }: PaginationState) => tableStore.setPageSize(pageSize)}
			pagination={{
				pageIndex: tableStore.getPageIndex(),
				pageSize: tableStore.getPageSize(),
			}}
			{...performanceProps}
			isActionLiner={isActionLiner}
			isEmptyAndDisabled={isEmptyAndDisabled}
			isGlobalFilter={isGlobalFilter}
			onSorting={(_sortingBy: SortingState) => {
				if (fetchReports || fetchMetrics) {
					tableStore.setSortingBy(_sortingBy);
				}
			}}
			{...rest}>
			{(props: { rows: Row<T>[]; sortedRows: Row<T>[] }) => {
				const { rows, sortedRows } = props;

				if (children) {
					TableActions = children({
						rows,
						sortedRows,
					});
				}
				const DefaultIndexActions = () => {
					if (!createRoute) {
						return null;
					}
					return (
						<PrimaryLink
							icon={'plus'}
							to={`${createRoute}`}
							width={'100px'}
							debugProps={{ dataAttrs: suffixToDataAttr('_create', dataAttrs) }}>
							Create
						</PrimaryLink>
					);
				};

				return {
					IndexActions: (() => {
						if (TableActions.IndexActions) {
							return TableActions.IndexActions;
						}
						if (!createRoute) {
							return null;
						}
						return DefaultIndexActions;
					})(),
					...TableActions,
				};
			}}
		</Table>
	);
});

export const ControlledTable = memo(_ControlledTable) as typeof _ControlledTable;
