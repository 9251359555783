import { declareIncludeParams, getParamsFromUrl } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { LinkProps } from 'react-router-dom';
import { Link, useSearchParams } from 'react-router-dom';

type IlinkProps = LinkProps & {
	delSearchParams?: boolean;
};

export const SearchParamsGlobalDecleration = ({ knownsSearchParams = [] }: { knownsSearchParams: string[] }) => {
	declareIncludeParams(knownsSearchParams);
	return null;
};

export const LinkWithParams = (props: IlinkProps) => {
	const { to, delSearchParams, ...rest } = props;
	if (!delSearchParams) {
		const [params] = useSearchParams();
		return <Link to={to + '?' + params.toString()} {...rest} />;
	}
	return <Link to={to} {...rest} />;
};
