import { Outlet, useParams } from 'react-router-dom';
import routes from '../../../modules/routes/index.routes';
import { Page } from '@monorepo/base/src/components/page/page';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';
import { NestedNav, NestedNavGroup, NestedNavItem, NestedNavPage } from '../../../modules/components/base-wrappers/nested-nav/nested-nav';
import { Tooltip } from '@monorepo/controlled/src/components/tooltip/tooltip/tooltip';
import { observer } from 'mobx-react';
import { Directions } from '@monorepo/base/src/components/buttons/buttons';
import { EntityNavigation } from '@monorepo/base/src/components/entity-navigation/entity-navigation';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { IMFPStore } from '../../../modules/stores';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { CampaignModel } from '../../../modules/models/campaign.model';
import { SidebarMode } from '@monorepo/base/src/components/sidebar/sidebar';
import { useNestedSidebar } from '@monorepo/controlled/src/hooks/use-nested-sidebar';

export const CampaignView = observer(() => {
	const { campaignStore } = useStores<IMFPStore>();
	const sortedRows = campaignStore.getSortedRows();
	const { currentRoute } = useRoute();
	useNestedSidebar({ enterMode: SidebarMode.LOCK });

	const { campaignId } = useParams();
	const navigate = useNavigateParams();

	if (!campaignId) {
		navigate(`${routes.campaigns.index()}`, { replace: true });
		return null;
	}

	return (
		<NestedNavPage>
			<NestedNav activeRoute={currentRoute}>
				<NestedNavItem icon={'bar-chart-square-02'}>
					<Tooltip direction={Directions.FromLeft} content={'Coming Soon'}>
						<span>Overview</span>
					</Tooltip>
				</NestedNavItem>
				<NestedNavItem icon={'globe-03'}>
					<Tooltip direction={Directions.FromLeft} content={'Coming Soon'}>
						<span>Landing Pages</span>
					</Tooltip>
				</NestedNavItem>
				<NestedNavItem icon={'timeline'}>
					<Tooltip direction={Directions.FromLeft} content={'Coming Soon'}>
						<span>Activity Log</span>
					</Tooltip>
				</NestedNavItem>
				<NestedNavItem icon={'settings-01'}>
					<Tooltip direction={Directions.FromLeft} content={'Coming Soon'}>
						<span>Settings</span>
					</Tooltip>
				</NestedNavItem>
				<NestedNavGroup title={'Placements'}>
					<NestedNavItem icon={'route'} to={campaignId ? `${routes.campaigns.channels(campaignId)}` : ''}>
						Channels
					</NestedNavItem>
					<NestedNavItem icon={'layout-alt-01'} to={campaignId ? `${routes.campaigns.sources(campaignId)}` : ''}>
						Sources
					</NestedNavItem>
				</NestedNavGroup>
			</NestedNav>
			<Page unstyled={true}>
				<EntityNavigation<CampaignModel>
					sortedRows={sortedRows}
					entityId={campaignId}
					navigationRouteFunc={id => routes.campaigns.channels(id)}
					entityName={'Campaigns'}
				/>
				<Outlet />
			</Page>
		</NestedNavPage>
	);
});
