import styles from './2fa.module.scss';
import { PrimaryButton } from '@monorepo/base/src/components/buttons/buttons';
import { Input } from '@monorepo/base/src/components/form/input/input';
import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import routes from '../../../modules/routes/index.routes';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { IMFPStore } from '../../../modules/stores';
import { sessionStorageHelper } from '@monorepo/tools/src/lib/tools/storage';
import { URL_TO_RETURN_AFTER_LOGOUT } from '../../../lib/consts/storage';
import { UserModel } from '../../../modules/models/user.model';
import { HttpHeaders } from '@monorepo/tools/src/lib/enums/headers';
import { ErrorMsg } from '../../../modules/components/error-msg/error-msg';
import { FullResponse } from '@monorepo/tools/src/lib/interfaces/url';
import { IUserRes } from '../../../modules/stores/users/user-crud.store';
import { useNavigateParams } from '@monorepo/tools/src/lib/hooks/tools/use-navigate-params';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

export const TwoFactorAuth: FC = observer(() => {
	const { userStore, authStore } = useStores<IMFPStore>();
	const crudStore = userStore.getCrud();
	const user = crudStore.getData();
	const formStore = crudStore.getFormStore();
	const error = crudStore.getHttpError();
	const isLoading = crudStore.getIsLoading();
	const navigate = useNavigateParams();
	const params = new URLSearchParams(useLocation().search);
	const userId = params.get('userId');
	const qrCode = params.get('qrCode');

	useEffect(() => {
		crudStore.reset();
	}, []);

	const onVerify = async ({ userId, token }: { userId: string; token: string }) => {
		const signInProps = await crudStore.validate2fa({ userId, token });
		if (!signInProps) {
			return;
		}
		const { body, headers } = signInProps as FullResponse<IUserRes>;
		if (!body?.payload?.user) {
			return;
		}
		const userAuthToken = headers?.find(h => h[0] === HttpHeaders.USER_TOKEN)?.[1];
		if (!userAuthToken) {
			return;
		}
		const user = new UserModel(body.payload.user);
		userStore.setUser(user);
		authStore.setToken(userAuthToken);

		if (!user.hasAccounts()) {
			return navigate(routes.accounts.createAccount());
		}

		if (!user.isVerified()) {
			return navigate(routes.auth.verify());
		}

		const urlToReturnTo = sessionStorageHelper.getItem(URL_TO_RETURN_AFTER_LOGOUT);
		navigate(urlToReturnTo || routes.general.home());
	};

	return (
		<div className={styles.mainWrapper}>
			<Helmet>
				<title>Manifesto - Login</title>
			</Helmet>

			<div className={styles.title}>Verify it's you.</div>
			{qrCode ? (
				<div className={styles.qrWrapper}>
					<img src={qrCode} alt="qr code" />
				</div>
			) : null}
			<div className={styles.subtitle}>Enter a 6-digit code from your two factor authenticator app.</div>

			<div className={styles.formWrapper}>
				<div className={styles.inputWrapper}>
					<Input
						className={styles.formInput}
						value={user.getCode()}
						placeholder={'Enter your code'}
						onValue={v => {
							if (Number(v) >= 0 || v === '') {
								user.setCode(v);
							}
						}}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								onVerify({ userId: userId + '', token: user.getCode() || '' });
							}
						}}
						requiredAsterisk={false}
						type={'text'}
						error={formStore.getErrors().get('code')?.getMsg()}
						maxLength={6}
						minLength={6}
					/>
				</div>
				<PrimaryButton
					isRotateIcon={isLoading}
					iconSize={'20px'}
					icon={isLoading ? 'loading-01' : ''}
					className={styles.verifyBtn}
					onClick={() => onVerify({ userId: userId + '', token: user.getCode() || '' })}>
					Verify
				</PrimaryButton>
				{error ? <ErrorMsg error={error} isIcon={false} classes={{ error: styles.error }} /> : null}
			</div>
		</div>
	);
});
