import { Icon } from '@monorepo/base/src/components/icon/icon';
import styles from './sidebar.module.scss';
import { Sidebar, SidebarButton, SidebarMode } from '@monorepo/base/src/components/sidebar/sidebar';
import { IControlledStore, useStores } from '@monorepo/controlled/src/hooks/use-stores';
import routes from '../../routes/index.routes'; // TODO - need to be dynamic
import { observer } from 'mobx-react';
import { useRoute } from '@monorepo/tools/src/lib/hooks/tools/use-route';

import { LinkWithParams } from '@monorepo/base/src/components/link-with-params/link-with-params';
import { useEffect } from 'react';

interface IItemList {
	mini?: boolean;
	title?: JSX.Element | string;
	route: string; // TODO - one of the routes
	icon?: string;
	color?: string;
}

// TODO - @MFP remove it from controlled
export const ControlledSidebar = observer(() => {
	const { sidebarStore } = useStores<IControlledStore>();
	const isSidebarOpen = sidebarStore.getIsSideBarOpen();

	useEffect(() => {
		sidebarStore.setSidebarOpen(true);
	}, []);

	const ItemList = ({ mini = false, title, route, icon, color }: IItemList) => {
		const { indexRoute, currentRoute } = useRoute();
		const isActiveRoute = currentRoute === route || currentRoute === `/${route}` || indexRoute === route;
		return (
			<li className={`${styles.itemWrapper} ${isActiveRoute ? styles.activeItem : ''}`}>
				<LinkWithParams to={route} className={styles.itemLink}>
					<div className={styles.item}>
						{icon ? (
							<Icon className={styles.icon} isMFP={true} color={color} size={'22px'}>
								{icon}
							</Icon>
						) : null}

						{mini ? null : <span className={styles.itemTitle}>{title}</span>}
					</div>
				</LinkWithParams>
			</li>
		);
	};

	const isMiniSidebar = (isHover: boolean) => {
		return (
			(!isHover && sidebarStore.getSidebarMode() === SidebarMode.HOVER) ||
			(!sidebarStore.getIsSideBarOpen() && sidebarStore.getSidebarMode() === SidebarMode.LOCK)
		);
	};

	const onClickSidebarButton = () => {
		sidebarStore.setSidebarOpen(!sidebarStore.getIsSideBarOpen());
		sidebarStore.setSidebarMode(SidebarMode.LOCK);
	};

	return (
		<Sidebar
			isOpen={sidebarStore.getIsSideBarOpen()}
			isAnimate={sidebarStore.getIsSideBarAnimate() || sidebarStore.getSidebarMode() === SidebarMode.HOVER}
			mode={sidebarStore.getSidebarMode()}>
			{props => {
				const { isHover } = props;
				const _isMiniSidebar = isMiniSidebar(isHover);
				return (
					<div className={styles.wrapper}>
						<ul className={`${styles.list} ${styles.fullList}`}>
							<ItemList icon={'bar-chart-square-02'} route={routes.general.home()} title={'Overview'} mini={_isMiniSidebar} />
							<ItemList icon={'building-07'} route={routes.accounts.index()} title={'Accounts'} mini={_isMiniSidebar} />
							<ItemList icon={'dataflow-04'} route={routes.advertisers.index()} title={'Advertisers'} mini={_isMiniSidebar} />
							<ItemList icon={'announcement-01'} route={routes.campaigns.index()} title={'Campaigns'} mini={_isMiniSidebar} />
						</ul>
						<SidebarButton
							classes={{ button: `${styles.hamburgerSidebar} ${isSidebarOpen ? `${styles.openSidebar}` : ''}` }}
							onClick={onClickSidebarButton}
							isOpen={isSidebarOpen}
							icon={'align-right-01'}
						/>
					</div>
				);
			}}
		</Sidebar>
	);
});
