import { getConfig } from '@monorepo/tools/src/lib/get-config';
import { ask } from '@monorepo/tools/src/lib/tools/ask/ask';
import { HttpHeaders } from '@monorepo/tools/src/lib/enums/headers';
import { BaseSyntheticEvent, useState } from 'react';
import { OAuthProviderEnum } from '../../enums/oauth-providers';
import { IUser } from '../../models/user.model';

const bouncerUrl = `${getConfig().env.sheriffRootApi}/bouncer`;

export const useLoginWith = () => {
	const [isError, setIsError] = useState(false);

	const signInWith = async (e: BaseSyntheticEvent, provider: OAuthProviderEnum) => {
		e.preventDefault();
		try {
			const response = await _signinWith(provider);
			if (!response) {
				throw new Error();
			}
			window.location.href = response;
		} catch (err) {
			setIsError(true);
		}
	};
	return { signInWith, isError };
};

const _signinWith = async (provider: OAuthProviderEnum) => {
	try {
		const authRoute = `${bouncerUrl}/users/sign/p/${provider}/in`;
		const link = await ask.get(authRoute);
		return link?.payload?.url;
	} catch (err) {
		console.error('Something went wrong', err);
	}
	return '';
};

interface IAuthRedirect {
	accessToken: string;
	provider: OAuthProviderEnum;
}

export const redirectHandler = async (
	props: IAuthRedirect
): Promise<{ userAuth: { token?: string }; user?: IUser; use2FA?: boolean; qrCode?: string }> => {
	const { accessToken, provider } = props;
	const { headers, body } = await ask.getWithHeaders<{ payload: { user: IUser; use2FA?: boolean; qrCode?: string } }>(
		`${bouncerUrl}/users/sign/p/${provider}/redirect?code=${accessToken}`
	);
	const userAuthToken = headers.find(h => h[0] === HttpHeaders.USER_TOKEN);
	return {
		userAuth: { token: userAuthToken?.[1] },
		user: body?.payload?.user,
		use2FA: body?.payload?.use2FA,
		qrCode: body?.payload?.qrCode,
	};
};
