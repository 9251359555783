import { TableLink } from '@monorepo/base/src/components/table/table-link/table-link';
import routes from '../../routes/index.routes';
import { useStores } from '@monorepo/controlled/src/hooks/use-stores';
import { AdvertiserModel, IAdvertiser } from '../../models/advertiser.model';
import { IEntityHeaders, IMergedHeadersReturn, renderSubRow } from './index.headers';
import { BetweenPagesStateProps } from '@monorepo/tools/src/lib/interfaces/global';
import { isSubRow } from '@monorepo/base/src/components/table/table';
import { IPerformanceItem } from '../../models/performance.model';
import { createColumnHelper } from '@tanstack/react-table';
import { IMFPStore } from '../../stores';
import { useWithPerformanceHeaders } from './with-performance.headers';
import { Tag } from '@monorepo/base/src/components/tag/tag';
import { mapToIcon, StatusType } from '../../enums/status';
import { Icon, IconFonts } from '@monorepo/base/src/components/icon/icon';
import { sentenceCase } from 'change-case';
import { mapToTagStatus } from '../../enums/status';
import styles from './headers.module.scss';
import { useSpecificHeaders } from './with-specific.headers';
import { useEffect } from 'react';
import { QuickActionsMenu } from '@monorepo/base/src/components/table/quick-actions-menu/quick-actions-menu';
import { TableMergeColumn } from '@monorepo/base/src/components/table/table-merge-column/table-merge-column';
import { TableSelectionHeader, TableSelectionCell } from '@monorepo/base/src/components/table/table-selection-cell/table-selection-cell';
import { usePageSettings } from '@monorepo/tools/src/lib/hooks/tools/use-page-settings';

const columnHelper = createColumnHelper<IAdvertiser & IPerformanceItem>();

export const useAdvertiserHeaders = (props: IEntityHeaders = {}): IMergedHeadersReturn<AdvertiserModel> => {
	const { EntityActions, onEdit, summary } = props;
	const { columns: specificColumns, columnVisibility: specificColumnVisibility } = useSpecificHeaders({
		specificColumns: ['accountName', 'accountId'],
	});
	const { sidebarStore } = useStores<IMFPStore>();
	const mergedPerforamnceHeaders = useWithPerformanceHeaders({ summaryItem: summary }).columns;
	const tableStore = usePageSettings().tableStore;

	useEffect(() => {
		if (tableStore) {
			if (Object.keys(tableStore.getColumnVisibility()).length === 0) {
				tableStore.setDefaultColumnVisibility({
					id: false,
					name: true,
					status: true,
					clicks: true,
					conversions: true,
					cost: true,
					revenue: true,
					roas: true,
					cpc: true,
					cpa: true,
					cpm: true,
					cvr: true,
					impressions: false,
					epc: false,
					win_rate: false,
					conv_val: false,
					...specificColumnVisibility,
				});
				tableStore?.setColumnVisibility(tableStore.getDefaultColumnVisibility());
			}
			if (tableStore.getSortingBy().length === 0) {
				tableStore.setSortingBy([
					{
						id: 'revenue',
						desc: true,
					},
				]);
			}
		}
	}, [tableStore]);

	return {
		columns: [
			columnHelper.display({
				id: 'selection',
				enableHiding: false,
				header: headerProps => <TableSelectionHeader headerProps={headerProps} />,
				cell: cellProps => {
					const { row } = cellProps;
					const adveriser = new AdvertiserModel(row.original);

					if (isSubRow(row)) {
						return <span></span>;
					}
					if (!EntityActions) {
						return null;
					}
					return (
						<TableMergeColumn>
							<TableSelectionCell cellProps={cellProps} />
							<QuickActionsMenu>
								<EntityActions entity={adveriser} />
							</QuickActionsMenu>
						</TableMergeColumn>
					);
				},
			}),
			columnHelper.accessor('id', {
				header: 'ID',
			}),
			columnHelper.accessor('name', {
				header: 'Name',
				enableHiding: false,
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return renderSubRow({ row });
					}
					const toEdit = (() => {
						if (onEdit) {
							return onEdit(row.getValue('id'));
						}
						return routes.advertisers.edit(row.getValue('id'));
					})();
					return (
						<TableLink
							to={routes.advertisers.campaigns(row.getValue('id'))}
							toEdit={toEdit}
							toEditState={{ isBlockRequest: true } as BetweenPagesStateProps}
							onClick={() => {
								if (!toEdit) {
									sidebarStore.setSidebarOpen(false);
								}
							}}>
							{row.getValue('name')}
						</TableLink>
					);
				},
			}),
			columnHelper.accessor('status', {
				header: 'Status',
				cell: info => {
					const { row } = info;
					if (isSubRow(row)) {
						return <span></span>;
					}
					const status = row.getValue('status') as string;
					const mappedStatus = mapToIcon(status?.toUpperCase() as StatusType);
					return (
						<Tag type={mapToTagStatus(status?.toUpperCase() as StatusType)}>
							<Icon
								className={styles[mappedStatus.className]}
								color={mappedStatus.color}
								size={'13px'}
								font={IconFonts.Outlined}
								isMFP={true}>
								{mappedStatus.icon}
							</Icon>
							{`${sentenceCase(row.getValue('status'))}`}
						</Tag>
					);
				},
			}),

			...specificColumns,
			...mergedPerforamnceHeaders,
		],
	};
};
